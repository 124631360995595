/*
COLORS

Primary: #eb2f64
Primary light: #FF3366
Primary dark: #BA265D

Grey light 1: #faf9f9
Grey light 2: #f4f2f2
Grey light 3: #f0eeee
Grey light 4: #ccc

Grey dark 1: #333
Grey dark 2: #777
Grey dark 3: #999

*/

:root {
  --color-primary: #eb2f64;
  --color-primary-light: #ff3366;
  --color-primary-dark: #ba265d;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
}

$bp-largest: 75em;
$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;

  // @media (max-width: 75em) {
  //   html {
  //     /* 9px / 16px  */
  //     font-size: 56.25%;
  //   }

  // @media (max-width: 59em) {
  //   html {
  //     /* 8px / 16px = 0.5 = 50% */
  //     font-size: 50%;
  //   }

  @media only screen and (max-width: 75em) {
    font-size: 56.25%;
  }

  @media only screen and (max-width: 59em) {
    font-size: 50%;
  }
}

body {
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  line-height: 1.6;
  color: var(--color-Grey-dark-2);
  min-height: 100vh;
}
