/* You can add global styles to this file, and also import other style files */
@import "app/sass/base/base";
@import "app/sass/abstracts/variables";

@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
:ng-deep .cdk-overlay-pane .x {
  margin: 1rem -1rem !important;
  padding: 0 1rem !important;
}
@import "ngx-toastr/toastr";

// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }
// body {
//   background-color: rgb(245, 245, 245) !important;
// }

// @import "app/sass/base/base";
// @import "app/sass/abstracts/variables";

*:focus {
  outline: none;
  /* outline: 4px dotted #e67e22; */
  /* outline-offset: 8px; */
  box-shadow: 0 0 0 0.8rem #30455b00;
}

.popover-backdrop {
  background-color: rgba(0, 0, 0, 0.293);
}

.angular-editor-textarea {
  margin-top: 0px !important;
  padding: 1rem !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  white-space: normal;
}